import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useDraggable } from 'react-use-draggable-scroll';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import {
  getActiveQuickAccess,
  getExplodedIAMTree,
  getExplodedTree,
  getLastVehicleDetail,
  setQuickAccessActiveCategory,
  setQuickAccessActiveSubCategory,
} from 'domains/catalog/Catalog.store';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getIAMTires, getVehicleTires } from 'domains/tires/Tire.store';
import { theme } from 'styles';
import { Box, CenteredSpin, CenterFlex, Flex, Icon, IconType, Link, Text, WithTooltip } from 'UI';
import { isLoading, useBreakpointSelector, useLarge } from 'utils';
import { CategoryFlex, SFlex, SubCategory } from './QuickAccess.styled';
import { LinkArray, QuickAccessItem, useQuickAccessLinks } from './useQuickAccessLinks';

export const useResetQuickAccessCategories = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(setQuickAccessActiveCategory(undefined));
      dispatch(setQuickAccessActiveSubCategory(undefined));
    };
    // eslint-disable-next-line
  }, []);
};

const Category = ({
  title,
  categoryKey,
  icon,
  activeCategory,
  link,
  firstSubcategory,
}: {
  title: string;
  categoryKey: string;
  icon: IconType;
  activeCategory: string | undefined;
  link?: string;
  firstSubcategory?: QuickAccessItem;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLarge = useLarge();
  const bps = useBreakpointSelector();
  const width = bps([100, 140]);
  const vehicle = useSelector(getLastVehicleDetail);
  const subcategoryLinkArray = vehicle?.catalogSource === 'IAM' ? firstSubcategory?.linkIam : firstSubcategory?.linkDh;
  const subcategoryLink =
    vehicle && subcategoryLinkArray
      ? `${getCatalogSourceUrl(vehicle.catalogSource)}/${
          vehicle.vehicleKey
        }${ROUTER_CATALOG_VEHICLE}/${subcategoryLinkArray.join('/')}`
      : undefined;

  const handleClickOnCategory = () => {
    dispatch(setQuickAccessActiveCategory(categoryKey));
    if (firstSubcategory && subcategoryLink) {
      dispatch(setQuickAccessActiveSubCategory(firstSubcategory.key));
      history.push(subcategoryLink);
    } else if (link) {
      history.push(link);
    } else {
      return;
    }
  };

  return (
    <WithTooltip title={title} placement={'top'}>
      <div>
        <CategoryFlex
          isActive={activeCategory === categoryKey}
          direction={'column'}
          justify={'center'}
          align={'center'}
          minWidth={width}
          maxWidth={width}
          minHeight={90}
          maxHeight={90}
          onClick={handleClickOnCategory}
        >
          <Icon IconComponent={icon} size={32} />
          {isLarge && (
            <Flex maxWidth={width} size={0}>
              <Text type={'text_dim'} noWrap ellipsis>
                {title}
              </Text>
            </Flex>
          )}
        </CategoryFlex>
      </div>
    </WithTooltip>
  );
};

const Subcategory = ({
  label,
  subCategoryKey,
  linkIam,
  linkDh,
  activeSubCategory,
}: {
  label: string;
  subCategoryKey: string;
  linkIam: LinkArray;
  linkDh: LinkArray;
  activeSubCategory: string | undefined;
}) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(getLastVehicleDetail);
  const isActive = subCategoryKey === activeSubCategory;
  const categoryLink = vehicle?.catalogSource === 'IAM' ? linkIam : linkDh;

  if (!vehicle) {
    return null;
  }

  return (
    <Link
      to={`${getCatalogSourceUrl(vehicle.catalogSource)}/${
        vehicle.vehicleKey
      }${ROUTER_CATALOG_VEHICLE}/${categoryLink.join('/')}`}
    >
      <SubCategory
        isActive={isActive}
        onClick={() => {
          dispatch(setQuickAccessActiveSubCategory(subCategoryKey));
        }}
      >
        <Flex minHeight={33} align={'center'}>
          <Text type={'text_dim'} displayStyle={isActive && 'link'}>
            {label}
          </Text>
        </Flex>
      </SubCategory>
    </Link>
  );
};

const QuickAccess = () => {
  const { subcategoryId } = useParams<{ subcategoryId: string }>();
  const dispatch = useDispatch();
  const { activeCategory, activeSubCategory } = useSelector(getActiveQuickAccess);
  const vehicle = useSelector(getLastVehicleDetail);
  const tires = useSelector(getVehicleTires);
  const iamTires = useSelector(getIAMTires);
  const iamTree = useSelector((state: RootState) => getExplodedIAMTree(state, vehicle?.vehicleKey));
  const dhTree = useSelector((state: RootState) => getExplodedTree(state, vehicle?.vehicleKey));
  const categories = useQuickAccessLinks();
  const subcategories = categories.filter((c) => c.key === activeCategory)[0]?.items;

  const scrollContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(scrollContainerRef);

  useEffect(() => {
    const activeSubCategoryData = subcategories?.find((s) => s.key === activeSubCategory);
    const activeSubCategoryCatalogId =
      vehicle?.catalogSource === 'IAM' ? activeSubCategoryData?.linkIam[2] : activeSubCategoryData?.linkDh[2];
    if (subcategoryId !== activeSubCategoryCatalogId) {
      dispatch(setQuickAccessActiveSubCategory(undefined));
    }
    //eslint-disable-next-line
  }, [subcategoryId]);

  const isAnythingLoading = () => {
    return isLoading(tires) || isLoading(iamTires) || isLoading(iamTree) || isLoading(dhTree);
  };

  return (
    <>
      <Box width={'100%'}>
        <Flex background={theme.color.grey90} justify={'center'}>
          <div style={{ display: 'flex', overflow: 'hidden' }} ref={scrollContainerRef} {...events}>
            {isAnythingLoading() ? (
              <CenterFlex style={{ height: 90 }}>
                <CenteredSpin />
              </CenterFlex>
            ) : (
              categories.map((d) => {
                const [firstSubcategory] = d.items || [];
                return (
                  <div key={d.key}>
                    <Category
                      title={d.title}
                      categoryKey={d.key}
                      icon={d.icon}
                      activeCategory={activeCategory}
                      link={d.link}
                      firstSubcategory={firstSubcategory}
                    />
                  </div>
                );
              })
            )}
          </div>
        </Flex>
      </Box>
      {activeCategory && !isAnythingLoading() && subcategories && subcategories?.length > 0 && (
        <SFlex minHeight={65} gap={15} align={'center'} wrap={'wrap'}>
          {subcategories?.map((sc) => (
            <Subcategory
              key={sc.key}
              subCategoryKey={sc.key}
              label={sc.label}
              linkIam={sc.linkIam}
              linkDh={sc.linkDh}
              activeSubCategory={activeSubCategory}
            />
          ))}
        </SFlex>
      )}
    </>
  );
};

export default QuickAccess;
