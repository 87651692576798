import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RangeFacet,
  TextFacet,
  TextFacetItem,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { List } from 'antd';
import { Button } from 'antd-v5';
import { TFunction } from 'i18next';
import { useDebouncedCallback } from 'use-debounce';
import { DownOutlinedIcon } from 'assets/icons';
import { FilterContainer, SBox, SFilterPanel, SListItem, Wrapper } from 'components/Filter/Filter.styled';
import { FilterFuelEfficiencyTag } from 'components/Filter/FilterColorBar';
import { TIRE } from 'domains/catalog/Catalog.types';
import { TireFilters } from 'domains/tires/Tire.types';
import { getRequestOptionTitle } from 'pages/MyOrdersPage/InProgressOrders/FilterOptions';
import { SAntDropdown } from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresSearchbar.styled';
import { theme } from 'styles';
import {
  Box,
  CenteredSpin,
  CenterFlex,
  Checkbox,
  Flex,
  Icon,
  InputNumber,
  InputRange,
  MarginBox,
  SectionCollapse,
  Text,
} from 'UI';
import {
  FILTER_DELIVERY,
  FILTER_FEATURES,
  FILTER_FUEL_EFFICIENCY,
  FILTER_NOISE_LEVEL,
  FILTER_POSITION,
  FILTER_PRICES,
  FILTER_PRODUCT_RANGE,
  FILTER_WET_GRIP,
  Filters,
  FiltersRange,
  FilterType,
  getDeliveryFilterData,
  getFilterFeaturesLabel,
  getFilterFuelConsumptionLabel,
  getFilterItemDisplayOrder,
  getFilterLabel,
  getFilterNoiseLevelLabel,
  getFilterProductRangeLabel,
  getPositionLabel,
  getTextFacets,
  getTireFilterItemDisplayOrder,
  isValidPosition,
  RANGE_FILTER_TYPE,
  RangeFacetLocal,
  sortFacets,
  TEXT_FILTER_TYPE,
  TextFacetLocal,
} from './Filter.types';

interface FilterProps {
  filters: Filters | TireFilters;
  textFilters: TextFacetLocal[] | undefined;
  rangeFilters: RangeFacetLocal[] | undefined;
  setTextFilters: (id: string, item: string) => void;
  setRangeFilters: (id: string, range?: FiltersRange) => void;
  resetFilters: () => void;
  loading: boolean;
  facetsMap: Map<string, { label: string; ids: string[] }>;
  usePrice?: UsePrice;
  requiredMin?: number;
  type?: FilterType;
  inline?: boolean;
}

interface UsePrice {
  showPriceless: boolean;
  setShowPriceless: (b: boolean) => void;
}

export const getLabel = (t: TFunction, type: string, item: string) => {
  switch (type) {
    case FILTER_POSITION:
      return getPositionLabel(item, t);
    case FILTER_DELIVERY:
      return getDeliveryFilterData(item, t)?.label;
    case FILTER_FUEL_EFFICIENCY:
      return getFilterFuelConsumptionLabel(item, t);
    case FILTER_FEATURES:
      return getFilterFeaturesLabel(item, t);
    case FILTER_PRODUCT_RANGE:
      return getFilterProductRangeLabel(item, t);
    case FILTER_NOISE_LEVEL:
      return getFilterNoiseLevelLabel(item, t);
    case 'VEHICLE':
      return t('order.in_progress.filter.tag.vehicle', 'Vehicle: {{vehicle}}', { vehicle: item });
    case 'REFERENCE_NUMBER':
      return t('order.in_progress.filter.tag.reference', 'Reference: {{reference}}', { reference: item });
    case 'ORDER':
      return t('order.in_progress.filter.tag.order', 'Order: {{order}}', { order: item });
    case 'ORDER_MARK':
      return t('order.in_progress.filter.tag.order_mark', 'Order mark: {{order_mark}}', { order_mark: item });
    case 'STATUS':
      return getRequestOptionTitle(t, item);
    default:
      return getFilterLabel(item, t);
  }
};

const DebouncedSlider = ({
  min,
  max,
  isDisabled,
  facet,
  filterValues,
  setRangeFilter,
}: {
  min: number;
  max: number;
  isDisabled: boolean;
  facet: RangeFacet;
  filterValues: [number, number];
  setRangeFilter: (id: string, range?: FiltersRange) => void;
}) => {
  return (
    <InputRange
      value={filterValues}
      min={min}
      max={max}
      disabled={isDisabled}
      step={0.1}
      onChange={(e) => {
        const [eMin, eMax] = e;
        if (eMin === min && eMax === max) {
          setRangeFilter(facet.id);
        } else {
          setRangeFilter(facet.id, { min: eMin, max: eMax });
        }
      }}
    />
  );
};

const DebouncedCheckbox = ({
  facet,
  item,
  isDisabled,
  getTextChecked,
  setTextFilter,
  t,
}: {
  facet: TextFacet;
  item: TextFacetItem;
  isDisabled: boolean;
  getTextChecked: (id: string, item: string) => boolean;
  setTextFilter: (id: string, item: string) => void;
  t: TFunction;
}) => {
  const isCheckedInStore = getTextChecked(facet.id, item.label);
  const [isChecked, setIsChecked] = useState(isCheckedInStore);

  useEffect(() => {
    setIsChecked(isCheckedInStore);
  }, [isCheckedInStore]);

  const renderLabel = () => {
    switch (facet.id) {
      case FILTER_FUEL_EFFICIENCY:
      case FILTER_WET_GRIP:
        return (
          <Flex direction={'row'}>
            <Flex minWidth={35}>
              <Text type={'light_14_black_65'} displayStyle={isDisabled && 'disabled'}>
                {`(${item.numberOfItems})`}
              </Text>
            </Flex>
            <FilterFuelEfficiencyTag label={item.label}>
              <Text type={'light_12_dark'} displayStyle={isDisabled && 'disabled'}>
                {`${getLabel(t, facet.id, item.label)}`}
              </Text>
            </FilterFuelEfficiencyTag>
          </Flex>
        );
      default:
        return (
          <Text type={'light_14_black_65'} displayStyle={isDisabled && 'disabled'}>
            {`${getLabel(t, facet.id, item.label)} (${item.numberOfItems})`}
          </Text>
        );
    }
  };
  return (
    <Checkbox
      onChange={() => {
        setIsChecked(!isChecked);
        setTextFilter(facet.id, item.label);
      }}
      checked={isChecked}
      disabled={isDisabled}
      label={renderLabel()}
    />
  );
};

const collapsedOnStart = [
  FILTER_FUEL_EFFICIENCY,
  FILTER_FEATURES,
  FILTER_PRODUCT_RANGE,
  FILTER_WET_GRIP,
  FILTER_NOISE_LEVEL,
];

const RangeFilter = ({
  min,
  max,
  isDisabled,
  facet,
  filterValues,
  setRangeFilter,
  usePrice,
  inline,
}: {
  min: number;
  max: number;
  isDisabled: boolean;
  facet: TextFacetLocal | RangeFacetLocal;
  filterValues: [number, number];
  setRangeFilter: (id: string, range?: FiltersRange) => void;
  usePrice?: UsePrice;
  inline?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex direction={'column'} maxWidth={inline ? 260 : 'auto'}>
      <DebouncedSlider
        min={min}
        max={max}
        filterValues={filterValues}
        facet={facet as RangeFacet}
        isDisabled={isDisabled}
        setRangeFilter={setRangeFilter}
      />
      <Flex>
        <Flex>
          <InputNumber
            value={filterValues[0]}
            disabled={isDisabled}
            min={min}
            max={max}
            onChange={(v) => {
              const eMin = Number(v);
              if (eMin < min || eMin > max) return;
              if (eMin === min && filterValues[1] === max) {
                setRangeFilter(facet.id);
              } else {
                setRangeFilter(facet.id, { min: eMin, max });
              }
            }}
          />
        </Flex>
        <Flex />
        <Flex>
          <InputNumber
            value={filterValues[1]}
            disabled={isDisabled}
            min={min}
            max={max}
            onChange={(v) => {
              const eMax = Number(v);
              if (eMax < min || eMax > max) return;
              if (filterValues[0] === min && eMax === max) {
                setRangeFilter(facet.id);
              } else {
                setRangeFilter(facet.id, { min, max: eMax });
              }
            }}
          />
        </Flex>
      </Flex>
      {facet.id === FILTER_PRICES && usePrice && (
        <MarginBox mt={25}>
          <Checkbox
            onChange={() => usePrice?.setShowPriceless(!usePrice.showPriceless)}
            checked={usePrice.showPriceless}
            label={
              <Text type={'text'}>
                {t('catalog.universal_products.filter.include_items_without_prices', 'Include items without prices')}
              </Text>
            }
          />
        </MarginBox>
      )}
    </Flex>
  );
};

const Filter = ({
  filters,
  textFilters = [],
  rangeFilters = [],
  setTextFilters,
  setRangeFilters,
  resetFilters,
  loading = false,
  facetsMap,
  usePrice,
  requiredMin = 1,
  type,
  inline,
}: FilterProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const facets: (TextFacetLocal | RangeFacetLocal)[] = useMemo(
    () =>
      [...getTextFacets(textFilters, requiredMin), ...rangeFilters]
        .map((fct) => {
          return {
            ...fct,
            order: type === TIRE ? getTireFilterItemDisplayOrder(fct) : getFilterItemDisplayOrder(fct),
          };
        })
        .sort(sortFacets),
    [textFilters, requiredMin, rangeFilters, type],
  );
  const defaultActiveKeys = [...facetsMap.keys()].filter((k) => !collapsedOnStart.includes(k));

  const handleResetFilters = () => {
    resetFilters();
    if (usePrice) {
      usePrice.setShowPriceless(false);
    }
  };

  const setTextFilter = useDebouncedCallback((id: string, item: string) => {
    setTextFilters(id, item);
  }, 200);

  const getTextChecked = (id: string, item: string) => {
    return (filters.textFilters.get(id) ?? []).includes(item);
  };

  const setRangeFilter = useDebouncedCallback((id: string, range?: { min: number; max: number }) => {
    setRangeFilters(id, range);
  }, 500);

  const getRangeValues = (id: string): [number, number] | undefined => {
    const idRangeItem = filters.rangeFilters.get(id);
    return idRangeItem && [idRangeItem.min, idRangeItem.max];
  };

  function getTextFilterOverlay(facet: TextFacetLocal | RangeFacetLocal, isPosition: boolean) {
    return (
      <SBox>
        <List
          dataSource={(facet as TextFacet).items}
          loading={loading}
          renderItem={(item) => {
            if (isPosition && !isValidPosition(item.label, t)) return null;
            const isDisabled = !facet.active || item.numberOfItems === 0;
            return (
              <SListItem>
                <DebouncedCheckbox
                  item={item}
                  facet={facet as TextFacet}
                  setTextFilter={setTextFilter}
                  isDisabled={isDisabled}
                  getTextChecked={getTextChecked}
                  t={t}
                />
              </SListItem>
            );
          }}
          split={false}
        />
      </SBox>
    );
  }

  const renderFacets = () => (
    <>
      {facets.map((facet) => {
        const facetLabel = facetsMap.get(facet.id)?.label ?? facet.id;
        const isPosition = facet.id === FILTER_POSITION;
        switch (facet.type) {
          case TEXT_FILTER_TYPE:
            return (
              <Wrapper>
                {inline ? (
                  <MarginBox mt={15}>
                    <SAntDropdown
                      overlay={<Box background={theme.color.white}>{getTextFilterOverlay(facet, isPosition)}</Box>}
                      trigger={['click']}
                      visible={open}
                      onVisibleChange={(visible) => setOpen(visible)}
                      bordered
                    >
                      <Button>
                        <MarginBox mr={12} />
                        <Text type={'lead_dim'}>{facetLabel}</Text>
                        <Flex direction={'row-reverse'}>
                          <Icon IconComponent={DownOutlinedIcon} size={18} color={'black'} />
                        </Flex>
                        <MarginBox mx={5} />
                      </Button>
                    </SAntDropdown>
                  </MarginBox>
                ) : (
                  <SectionCollapse
                    key={facet.id}
                    position={'end'}
                    noShadow
                    defaultActiveKey={defaultActiveKeys}
                    width={300}
                  >
                    <SFilterPanel
                      header={
                        <Flex align={'center'}>
                          <Text type={'text_dim'}>{facetLabel}</Text>
                        </Flex>
                      }
                      key={facet.id}
                    >
                      {getTextFilterOverlay(facet, isPosition)}
                    </SFilterPanel>
                  </SectionCollapse>
                )}
              </Wrapper>
            );
          case RANGE_FILTER_TYPE: {
            const { min = 0, max = 0 } = facet as RangeFacet;
            const filterValues = getRangeValues(facet.id) ?? [min, max];
            const [fMin, fMax] = filterValues;
            const isChanged = fMin !== min || fMax !== max;
            const isDisabled =
              (!facet.active ||
                (facet as RangeFacet).numberOfItems === 0 ||
                (facet as RangeFacet).numberOfItems === 1 ||
                min === max) &&
              !isChanged;

            return (
              <Wrapper>
                {inline ? (
                  <Flex>
                    <MarginBox mt={3} mr={5}>
                      <Text type={'light_dimmer'}>{facetLabel}</Text>
                    </MarginBox>
                    <RangeFilter
                      min={min}
                      max={max}
                      filterValues={filterValues}
                      facet={facet}
                      isDisabled={isDisabled}
                      setRangeFilter={setRangeFilter}
                      usePrice={usePrice}
                      inline={inline}
                    />
                  </Flex>
                ) : (
                  <SectionCollapse
                    key={facet.id}
                    position={'end'}
                    noShadow
                    defaultActiveKey={defaultActiveKeys}
                    width={300}
                  >
                    <SFilterPanel header={<Text type={'text_dim'}>{facetLabel}</Text>} key={facet.id}>
                      <SBox>
                        {loading ? (
                          <CenteredSpin />
                        ) : (
                          <RangeFilter
                            min={min}
                            max={max}
                            filterValues={filterValues}
                            facet={facet}
                            isDisabled={isDisabled}
                            setRangeFilter={setRangeFilter}
                            usePrice={usePrice}
                            inline={inline}
                          />
                        )}
                      </SBox>
                    </SFilterPanel>
                  </SectionCollapse>
                )}
              </Wrapper>
            );
          }
          default:
            return <></>;
        }
      })}
    </>
  );

  return (
    <>
      {inline ? (
        <Flex direction={'row-reverse'} gap={10}>
          {renderFacets()}
        </Flex>
      ) : (
        <FilterContainer>
          <Box width={'100%'}>
            <MarginBox ml={15} mt={-15} mr={10}>
              <Flex justify={'space-between'} align={'center'}>
                <Text type={'h3'}>{t('common.filter.filter_by', 'Filter by')}</Text>
                <Box>
                  <Flex>
                    <Text type={'light_14_black_85'} cursor={'pointer'} onClick={handleResetFilters} hoverUnderLine>
                      {t('common.filter.action.clear_filters', 'Clear filters')}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </MarginBox>
            {facets.length === 0 && (
              <MarginBox my={10}>
                <CenterFlex>
                  <Text type={'text_dim'}>{t('common.filter.no_filter', 'No filter available')}</Text>
                </CenterFlex>
              </MarginBox>
            )}
            {renderFacets()}
          </Box>
        </FilterContainer>
      )}
    </>
  );
};

export default Filter;
