import React from 'react';
import { useSelector } from 'react-redux';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { getDHReference } from 'domains/references';
import { IndexHeader } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/IndexHeader/IndexHeader';
import { SelectedIndexType } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/SparePartsSection';
import { sentenceCase, Text } from 'UI';
import { getData } from 'utils';
import { OFFSET_LINK_TABS, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { IndexType } from 'utils/svg/common';

export interface PlateReferencesHeaderProps {
  detail: PlateReferencesDetail;
  vehicleKey: string | undefined;
  isSelected?: boolean;
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  refIndex: number;
}

export const PlateReferencesHeader = ({
  detail,
  vehicleKey,
  isSelected,
  setSelectedIndex,
  setHighlightedIndex,
  refIndex,
}: PlateReferencesHeaderProps) => {
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const handleScrollHighlight = (indexKey: string) => {
    setSelectedIndex(indexKey);
    setHighlightedIndex(indexKey);
    scrollToTopSmooth(
      (document.getElementById('subcategory_section_container')?.offsetTop ?? 0) - offsetTop - OFFSET_LINK_TABS,
    );
  };

  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: detail.referenceNumber, vehicleKey }),
  );

  return (
    <FirstHelpPopin
      streamId={ROUTER_CATALOG_DH_L3}
      popinId={`${ROUTER_CATALOG_DH_L3}_plate_index`}
      placement={'top'}
      skip={refIndex !== 0}
    >
      <IndexHeader id={`plate-reference-index-${detail.index}`} isSelected={isSelected} data-cy={'bar-reference-index'}>
        <Text
          type={isSelected ? 'h6_white' : 'h6'}
          cursor={'pointer'}
          disableGutter
          onClick={() => handleScrollHighlight(detail.index.toString())}
        >
          {`${detail.index}. ${sentenceCase(getData(reference)?.commercialName ?? '')}`}
        </Text>
      </IndexHeader>
    </FirstHelpPopin>
  );
};
