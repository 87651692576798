import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { RootState } from 'app/AppStore';
import CloseButton from 'components/CloseButton';
import { FILTER_BRAND, FILTER_SEASON } from 'components/Filter/Filter.types';
import { getBannersCount } from 'domains/information/Information.store';
import { resetTireFilters } from 'domains/tires/Tire.store';
import TireReferencesSection from 'pages/TiresPage/TireReferencesSection/TireReferencesSection';
import { Flex, MarginBox } from 'UI';
import { scrollToElementSmooth } from 'utils/hooks/useResetScroll';
import { TireReferencesPopupContainer } from './TireReferencesPopup.styled';

interface TireReferencesPopupProps extends PropsWithChildren {
  id: string;
  searchParamsBase64: string;
  isSelected: boolean;
  setSelectedTire: (tire: string | undefined) => void;
  handleSearch: () => void;
  marginTop?: number;
  catalogSource?: CatalogSource;
  brandsFilter?: string[];
  seasonFilter?: string;
}

export const TireReferencesPopup = ({
  children,
  id,
  searchParamsBase64,
  isSelected,
  setSelectedTire,
  handleSearch,
  marginTop = 55,
  catalogSource = 'DATAHUB',
  brandsFilter,
  seasonFilter,
}: TireReferencesPopupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const isBanner =
    useSelector((state: RootState) =>
      getBannersCount(state, catalogSource === 'DATAHUB' ? 'RENAULT_CATALOG' : 'OTHER_BRANDS_CATALOG'),
    ) > 0;

  useEffect(() => {
    return () => {
      dispatch(resetTireFilters());
    };
  }, [dispatch]);

  const handleVisibleChange = (visible: boolean) => {
    if (visible) {
      setSelectedTire(id);
      dispatch(resetTireFilters());
      handleSearch();
      scrollToElementSmooth(ref?.current as Element, isBanner);
    } else {
      setSelectedTire(undefined);
      dispatch(resetTireFilters());
    }
  };

  const getInitialTextFilters = () => {
    const filters = [];
    if (brandsFilter && brandsFilter.length > 0) {
      brandsFilter.map((brand) => filters.push({ id: FILTER_BRAND, item: brand }));
    }
    if (seasonFilter) {
      filters.push({ id: FILTER_SEASON, item: seasonFilter });
    }
    return filters.length > 0 ? [...filters] : undefined;
  };

  return (
    <Flex direction={'column'}>
      <Flex ref={ref} onClick={() => handleVisibleChange(!isSelected)}>
        {children}
      </Flex>
      {isSelected && (
        <TireReferencesPopupContainer direction={'row'} maxHeight={750} minWidth={760} marginTop={marginTop}>
          <TireReferencesSection searchParamsBase64={searchParamsBase64} initialTextFilters={getInitialTextFilters()} />
          <MarginBox mr={15} />
          <CloseButton onClose={() => handleVisibleChange(false)} align={'right'} type={'dark_12_white_bold'} />
        </TireReferencesPopupContainer>
      )}
    </Flex>
  );
};
